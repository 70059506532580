

























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { API } from '@/types';
import TwitchPlayer from '@/components/Dashboard/TwitchPlayer.vue';
import { apiModify } from '@/api';
import { storeModule } from '../../store';

@Component({
  components: {
    TwitchPlayer,
  },
})
export default class extends Vue {
  @Prop({ type: Object, required: true }) readonly player!: API.Players.GET;
  @Prop(Boolean) readonly unmuted!: boolean;
  @Prop(Boolean) readonly showTwitch!: boolean;
  active = this.player.active || false;
  activeChanging = false;

  @Watch('player.active')
  onPlayerActiveChange(val: boolean): void {
    this.active = val;
  }

  get name(): string {
    return this.player.user?.name || '?';
  }

  async changeActive(state: boolean): Promise<void> {
    try {
      this.activeChanging = true;
      const { data } = await apiModify('players', this.player.id, { active: state });
      storeModule.updateStoredPlayer(data);
    } catch (err) {
      this.active = this.player.active;
    }
    this.activeChanging = false;
  }
}
