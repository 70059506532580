
























































import { Vue, Component, Watch } from 'vue-property-decorator';
import EventFeedCard from '@/components/Dashboard/EventFeedCard.vue';
import { API } from '@/types';
import { rootModule } from '@/store';
import { Socket } from 'vue-socket.io-extended';
import { apiGETMulti } from '@/api';

@Component({
  components: {
    EventFeedCard,
  },
})
export default class extends Vue {
  eventLog: API.EventLog.GET[] = [];
  loading = false;
  dialog = false;
  maxEntriesToDisplay = 10;

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  // TODO: Handle errors!
  async loadAPIData(): Promise<void> {
    this.loading = true;
    const { data } = (await apiGETMulti('eventLog', {
      max: 10,
      offset: this.eventLog.length,
      embed: ['card', 'user'],
      eventId: this.selectedEvent ?? undefined,
      cleared: false,
    }));
    Vue.set(this, 'eventLog', this.eventLog.concat(data));
    this.loading = false;
  }

  @Socket('eventLogEntryModified')
  socketEntryModified(newVal: API.EventLog.GET | null, oldVal: API.EventLog.GET | null): void {
    const index = this.eventLog.findIndex((i) => i.id === oldVal?.id);
    if (index >= 0 && newVal && oldVal) {
      if (newVal.cleared && !oldVal.cleared) {
        this.eventLog.splice(index, 1);
      } else {
        Vue.set(this.eventLog, index, { ...this.eventLog[index], ...newVal });
      }
    }
    if (newVal && !newVal.cleared && !oldVal) {
      this.eventLog.unshift(newVal);
      if (this.eventLog.length > this.maxEntriesToDisplay) {
        this.eventLog.length = this.maxEntriesToDisplay;
      }
    }
    if (!newVal && oldVal) {
      this.eventLog.splice(index, 1);
    }
  }

  // Updates the embedded users if currently in any loaded event log entities.
  @Socket('userModified')
  sockerUserModified(newVal: API.Users.GET | null, oldVal: API.Users.GET | null): void {
    this.eventLog.forEach((entry, i) => {
      if (newVal && oldVal && entry.userId === oldVal.id) {
        Vue.set(this.eventLog[i], 'user', { ...this.eventLog[i].user, ...newVal });
      }
    });
  }

  // Updates the embedded player card if currently in any loaded event log entities.
  @Socket('playerCardModified')
  socketCardModified(newVal: API.PlayerCards.GET | null, oldVal: API.PlayerCards.GET | null): void {
    this.eventLog.forEach((entry, i) => {
      if (newVal && oldVal && entry.eventId === oldVal.id) {
        Vue.set(this.eventLog[i], 'card', { ...this.eventLog[i].card, ...newVal });
      }
    });
  }

  @Watch('dialog')
  onDialogChange(val: boolean): void {
    if (val) this.loadAPIData();
    else Vue.set(this, 'eventLog', []);
  }
}
