





























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { API } from '@/types';
import { jwt } from '@/auth';
import Card from './MsgSystem/Card.vue';
import axios from 'axios';
import { apiGETMulti, apiModify, axiosConfig } from '@/api';
import { rootModule } from '@/store';
import { Socket } from 'vue-socket.io-extended';

dayjs.extend(relativeTime);

@Component({
  components: {
    Card,
  },
})
export default class extends Vue {
  @Prop({ type: Number, required: true }) readonly playerId!: number;
  playerCards: API.PlayerCards.GET[] = [];
  dayjs = dayjs;
  evtBoxTxt: string | null = null;
  evtAttachedCard: number | null = null;
  evtTwitchClipUrl: string | null = null;
  evtAttachedFile: File | null = null;
  evtLoading = false;
  evtSuccess = false;
  loading = false;
  maxCardsToDisplay = 10;

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  // TODO: Handle errors!
  async loadAPIData(): Promise<void> {
    this.loading = true;
    const { data } = (await apiGETMulti('playerCards', {
      max: 10,
      offset: this.playerCards.length,
      playerId: this.playerId,
    }));
    Vue.set(this, 'playerCards', this.playerCards.concat(data));
    this.loading = false;
  }

  @Socket('playerCardModified')
  socketEntryModified(
    newVal: API.PlayerCards.GET | null,
    oldVal: API.PlayerCards.GET | null,
  ): void {
    const index = this.playerCards.findIndex((i) => i.id === oldVal?.id);
    if (index >= 0 && newVal && oldVal && newVal.playerId === this.playerId) {
      Vue.set(this.playerCards, index, { ...this.playerCards[index], ...newVal });
    }
    if (newVal && !oldVal && newVal.playerId === this.playerId) {
      this.playerCards.unshift(newVal);
      if (this.playerCards.length > this.maxCardsToDisplay) {
        this.playerCards.length = this.maxCardsToDisplay;
      }
    }
    if ((!newVal && oldVal)
    || (newVal?.playerId !== this.playerId && oldVal?.playerId === this.playerId)) {
      this.playerCards.splice(index, 1);
    }
  }

  attachCard(cardID?: number): void {
    this.evtAttachedCard = cardID ?? null;
  }

  async sendMsg(): Promise<void> {
    // Trigger the save.
    this.evtLoading = true;

    if (this.evtAttachedCard || this.evtBoxTxt || this.evtAttachedFile) {
      const body = {
        playerId: this.playerId,
        cardId: this.evtAttachedCard ?? undefined,
        msg: this.evtBoxTxt || undefined,
        clipTwitchUrl: this.evtTwitchClipUrl || undefined,
      };
      const formData = new FormData();

      Object.entries(body).forEach(([k, v]) => {
        if (typeof v !== 'undefined' && v !== null) {
          formData.append(k, typeof v === 'number' ? v.toString() : v);
        }
      });
      if (this.evtAttachedFile) {
        formData.append('file', this.evtAttachedFile);
      }
      await axios.post('/event_log', formData, axiosConfig());
      if (this.evtAttachedCard) {
        const { data } = await apiModify('playerCards', this.evtAttachedCard, { sentOnce: true });
        const index = this.playerCards.findIndex((c) => c.id === data.id);
        if (index >= 0) Vue.set(this.playerCards, index, { ...this.playerCards[index], ...data });
      }
    }

    // Clear the contents of this event.
    this.evtBoxTxt = null;
    this.evtAttachedCard = null;
    this.evtTwitchClipUrl = null;
    this.evtAttachedFile = null;

    // Wait 0.5s then set success status.
    await new Promise((res) => window.setTimeout(res, 500));
    this.evtLoading = false;
    this.evtSuccess = true;

    // Wait 1s then turn off success status.
    await new Promise((res) => window.setTimeout(res, 1000));
    this.evtSuccess = false;
  }

  get textFieldLabel(): string {
    if (this.evtLoading) {
      return 'Saving...';
    }
    if (this.evtSuccess) {
      return 'Saved!';
    }
    return 'Create a note about this stream';
  }

  get formattedCards(): API.PlayerCards.GET[] {
    return this.playerCards
      .sort((a, b) => Date.parse(b.creationTimestamp) - Date.parse(a.creationTimestamp));
  }

  get attachedCardMsg(): string {
    const msg = this.playerCards.find((s) => s.id === this.evtAttachedCard)?.msg || '?';
    return (msg.length > 30) ? `${msg.substring(0, 30)}...` : msg;
  }

  get isAdmin(): boolean {
    return !!jwt?.user.roles.includes('admin');
  }

  created(): void {
    this.loadAPIData();
  }
}
