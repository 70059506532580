




















































import { Vue, Component, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Tooltip from '@/components/Dashboard/Tooltip.vue';
import { API } from '@/types';

dayjs.extend(relativeTime);

@Component({
  components: {
    Tooltip,
  },
})
export default class extends Vue {
  @Prop({ type: Object, required: true }) readonly card!: API.PlayerCards.GET;
  dayjs = dayjs;
  fromNow = '?';

  created(): void {
    this.fromNow = dayjs(this.card.creationTimestamp).fromNow();
    window.setInterval(() => {
      this.fromNow = dayjs(this.card.creationTimestamp).fromNow();
    }, 1000);
  }

  attachCard(cardID?: number): void {
    this.$emit('attachCard', cardID);
  }
}
