




















































































import clone from 'clone';
import { Vue, Component } from 'vue-property-decorator';
import { API } from '@/types';
import EventFeed from './components/EventFeed.vue';
import TopHalf from './components/TopHalf.vue';
import MsgSystem from './components/MsgSystem.vue';
import { rootModule } from '@/store';
import { storeNS } from '../store';

@Component({
  components: {
    TopHalf,
    MsgSystem,
    EventFeed,
  },
})
export default class extends Vue {
  @storeNS.State readonly players!: API.Players.GET[];
  selectedPlayers: number[] = [];
  dialog = true;
  showTwitch = false;

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  get playersSorted(): API.Players.GET[] {
    return clone(this.players)
      .sort((a, b) => {
        const aName = a.user?.name || '?';
        const bName = b.user?.name || '?';
        return aName.localeCompare(bName, 'en', { sensitivity: 'base' });
      });
  }

  get playersFiltered(): API.Players.GET[] {
    return this.playersSorted.filter((p) => this.selectedPlayers.includes(p.id));
  }
}
